/*import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/



//Import React
import React, { useEffect, useState } from 'react'

//Import Set up frontend
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import awsExports from "./aws-exports";

//Import AppSync GraphQL
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'

//Import UI-Components
import {   Header } from './ui-components';
import {   Input } from './ui-components';
import {   ArticelListCollection } from './ui-components';
import {   Footer } from './ui-components';



//Import Authentification
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

//Start Amplify update automatically
Amplify.configure(awsExports);

//Variables
const initialState = { name: '', description: '' }


const App = ({ signOut, user }) => {  
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) { console.log('error creating todo:', err) }
  }

  return (
    <div style={styles.container}>
      {/*<h2>Shoppinglist by CB</h2>
      

      <Heading level={1}>Hi {user.username}</Heading>
      <Button onClick={signOut}>Ausloggen</Button>
      <h2> </h2>
      
      <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name}
        placeholder="Artikel"
      />
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Beschreibung"
      />
      <button style={styles.button} onClick={addTodo}>Hinzufügen</button>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoName}>{todo.name}</p>
            <p style={styles.todoDescription}>{todo.description}</p>
          </div>
        ))
      }
      */}

      <Header />
      <Input />
      <ArticelListCollection />
      <Footer />

    
    </div>
    
  )
}

const styles = {
  container: { width: '70%',  margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 , backgroundColor: '#F8F8F8'},
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withAuthenticator(App);