/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, Text, View } from "@aws-amplify/ui-react";
export default function ArticelList(props) {
  const { todo, overrides, ...rest } = props;
  return (
    <View
      width="910px"
      height="77px"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(248,248,248,1)"
      {...rest}
      {...getOverrideProps(overrides, "ArticelList")}
    >
      <View
        position="absolute"
        top="0%"
        bottom="0%"
        left="2.42%"
        right="1.98%"
        overflow="hidden"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 2")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="500"
          color="rgba(0,0,0,1)"
          lineHeight="18.75px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="center"
          position="absolute"
          top="31.17%"
          bottom="31.46%"
          left="16.62%"
          right="48.87%"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={todo?.name}
          {...getOverrideProps(overrides, "ArtikelName")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="500"
          color="rgba(0,0,0,1)"
          lineHeight="18.75px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="center"
          position="absolute"
          top="31.17%"
          bottom="31.46%"
          left="54.1%"
          right="18.6%"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={todo?.description}
          {...getOverrideProps(overrides, "ArtikelMenge")}
        ></Text>
        <View
          padding="0px 0px 0px 0px"
          position="absolute"
          top="23.38%"
          bottom="24.68%"
          left="9.43%"
          right="85.98%"
          {...getOverrideProps(overrides, "check_box2281938")}
        >
          <View
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(217,217,217,1)"
            {...getOverrideProps(overrides, "Bounding box")}
          ></View>
          <Icon
            width="30px"
            height="30px"
            viewBox={{ minX: 0, minY: 0, width: 30, height: 30 }}
            paths={[
              {
                d: "M12.6667 22L24.4167 10.25L22.0833 7.91667L12.6667 17.3333L7.91667 12.5833L5.58333 14.9167L12.6667 22ZM3.33333 30C2.41667 30 1.63167 29.6739 0.978333 29.0217C0.326111 28.3683 0 27.5833 0 26.6667L0 3.33333C0 2.41667 0.326111 1.63167 0.978333 0.978333C1.63167 0.326111 2.41667 0 3.33333 0L26.6667 0C27.5833 0 28.3683 0.326111 29.0217 0.978333C29.6739 1.63167 30 2.41667 30 3.33333L30 26.6667C30 27.5833 29.6739 28.3683 29.0217 29.0217C28.3683 29.6739 27.5833 30 26.6667 30L3.33333 30ZM3.33333 26.6667L26.6667 26.6667L26.6667 3.33333L3.33333 3.33333L3.33333 26.6667Z",
                fill: "rgba(28,27,31,1)",
                fillRule: "nonzero",
              },
            ]}
            position="absolute"
            top="12.5%"
            bottom="12.5%"
            left="12.5%"
            right="12.5%"
            {...getOverrideProps(overrides, "check_box2281940")}
          ></Icon>
        </View>
        <Icon
          width="25px"
          height="25px"
          viewBox={{ minX: 0, minY: 0, width: 25, height: 25 }}
          paths={[
            {
              d: "M3.40932 0.000319673C3.11856 0.000319673 2.82748 0.111077 2.6059 0.33323L0.33323 2.6059C-0.111077 3.05021 -0.111077 3.76958 0.33323 4.21275L8.62048 12.5L0.33323 20.7873C-0.111077 21.2316 -0.111077 21.9509 0.33323 22.3941L2.6059 24.6668C3.05021 25.1111 3.76958 25.1111 4.21275 24.6668L12.5 16.3795L20.7873 24.6668C21.2304 25.1111 21.9509 25.1111 22.3941 24.6668L24.6668 22.3941C25.1111 21.9498 25.1111 21.2304 24.6668 20.7873L16.3795 12.5L24.6668 4.21275C25.1111 3.76958 25.1111 3.04907 24.6668 2.6059L22.3941 0.33323C21.9498 -0.111077 21.2304 -0.111077 20.7873 0.33323L12.5 8.62048L4.21275 0.33323C3.99059 0.111077 3.70008 0.000319673 3.40932 0.000319673Z",
              fill: "rgba(171,171,171,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="32.47%"
          bottom="35.06%"
          left="83.34%"
          right="13.78%"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </View>
    </View>
  );
}
