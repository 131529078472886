/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps, useAuth } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function Header(props) {
  const { overrides, ...rest } = props;
  const authAttributes = useAuth().user?.attributes ?? {};
  return (
    <View
      width="1368px"
      height="106px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Header")}
    >
      <View
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(248,248,248,1)"
        {...getOverrideProps(overrides, "BG")}
      ></View>
      <Image
        width="90px"
        height="84.38px"
        position="absolute"
        top="13px"
        left="15px"
        padding="0px 0px 0px 0px"
        src="https://img.icons8.com/nolan/64/qr-code.png"
        {...getOverrideProps(overrides, "icons8-qr-code-64 1")}
      ></Image>
      <Text
        fontFamily="Inter"
        fontSize="24px"
        fontWeight="500"
        color="rgba(0,0,0,1)"
        lineHeight="28.125px"
        textAlign="center"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        width="213px"
        height="54px"
        position="absolute"
        top="26px"
        left="131px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="QR-Shopping-List"
        {...getOverrideProps(overrides, "QR-Shopping-List")}
      ></Text>
      <View
        width="295px"
        height="106px"
        position="absolute"
        top="0px"
        left="1073px"
        overflow="hidden"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 3")}
      >
        <Flex
          gap="0"
          position="absolute"
          top="42.45%"
          bottom="20.75%"
          left="58.31%"
          right="7.12%"
          width="102px"
          height="39px"
          justifyContent="center"
          alignItems="center"
          border="1px SOLID rgba(0,0,0,0)"
          borderRadius="100px"
          padding="7px 15px 7px 15px"
          backgroundColor="rgba(171,171,171,1)"
          {...getOverrideProps(overrides, "Button")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Logout"
            {...getOverrideProps(overrides, "label")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          position="absolute"
          top="-4.97%"
          bottom="88.93%"
          left="calc(50% - 35px - -82.5px)"
          direction="column"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Button/Secondary")}
        ></Flex>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(102,102,102,1)"
          lineHeight="16.40625px"
          textAlign="right"
          display="flex"
          direction="column"
          justifyContent="flex-end"
          position="absolute"
          top="11.32%"
          bottom="67.92%"
          left="5.42%"
          right="9.83%"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={authAttributes["email"]}
          {...getOverrideProps(overrides, "UserName")}
        ></Text>
      </View>
    </View>
  );
}
