/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useDataStoreCreateAction,
} from "@aws-amplify/ui-react/internal";
import { Todo } from "../models";
import { schema } from "../models/schema";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function Input(props) {
  const { todo, overrides, ...rest } = props;
  const labelOnClick = useDataStoreCreateAction({
    fields: {},
    model: Todo,
    schema: schema,
  });
  return (
    <Flex
      gap="10px"
      direction="column"
      position="relative"
      padding="30px 13px 30px 13px"
      backgroundColor="rgba(248,248,248,1)"
      {...rest}
      {...getOverrideProps(overrides, "Input")}
    >
      <View
        width="875.31px"
        height="104.36px"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1")}
      >
        <Icon
          width="870.3485717773438px"
          height="0.92352294921875px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 870.3485717773438,
            height: 0.92352294921875,
          }}
          paths={[
            {
              d: "M0 -5.55112e-17L870.349 -5.55112e-17L870.349 -1L0 -1L0 -5.55112e-17Z",
              stroke: "rgba(204,206,211,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          position="absolute"
          top="87.61%"
          bottom="12.39%"
          left="0.57%"
          right="0%"
          transformOrigin="top left"
          transform="rotate(-0.06deg)"
          {...getOverrideProps(overrides, "Line 5")}
        ></Icon>
        <Flex
          gap="0"
          position="absolute"
          top="33.54%"
          bottom="23.34%"
          left="80.66%"
          right="3.01%"
          width="143px"
          height="45px"
          justifyContent="center"
          alignItems="center"
          border="1px SOLID rgba(0,0,0,0)"
          borderRadius="100px"
          padding="7px 15px 7px 15px"
          backgroundColor="rgba(75,148,96,1)"
          {...getOverrideProps(overrides, "Button")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Hinzufügen"
            onClick={() => {
              labelOnClick();
            }}
            {...getOverrideProps(overrides, "label")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          position="absolute"
          top="33.54%"
          bottom="23.34%"
          left="15.42%"
          right="50.3%"
          direction="column"
          width="300px"
          height="45px"
          justifyContent="center"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TextField2352232")}
        >
          <Flex
            gap="0"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InputGroup2352235")}
          >
            <Flex
              gap="10px"
              direction="column"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              border="1px SOLID rgba(174,179,183,1)"
              borderRadius="5px"
              padding="8px 16px 8px 16px"
              {...getOverrideProps(overrides, "Input2352236")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(128,128,128,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Neuer Artikel"
                {...getOverrideProps(overrides, "placeholder2352237")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="8px"
          position="absolute"
          top="33.54%"
          bottom="23.34%"
          left="52.78%"
          right="23.23%"
          direction="column"
          width="210px"
          height="45px"
          justifyContent="center"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TextField2352217")}
        >
          <Flex
            gap="0"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InputGroup2352220")}
          >
            <Flex
              gap="10px"
              direction="column"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              border="1px SOLID rgba(174,179,183,1)"
              borderRadius="5px"
              padding="8px 16px 8px 16px"
              {...getOverrideProps(overrides, "Input2352221")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(128,128,128,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Menge"
                {...getOverrideProps(overrides, "placeholder2352222")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </View>
    </Flex>
  );
}
